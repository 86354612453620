import React from 'react';
import './styles.css';
import { useGoogleLogin } from '@react-oauth/google';
import { autenticarParceiroSocial } from '../../../store/modules/autenticacao/actions';
import { useDispatch } from 'react-redux';

const GoogleLogin = () => {

  const dispatch = useDispatch();
  const responseGoogle = (e) => {
    console.log(`googleAuthentication`, e, {index: 'googleOauth',social_token: e.access_token});
    dispatch(autenticarParceiroSocial('googleOauth', e.access_token));
  }

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      responseGoogle(tokenResponse);
    },
  });

  return (
    <button type={'button'} onClick={() => login()} className={'btn-primario btn-primario3'}><i className={'fa fa-google'}></i> Login com Google</button>
  );
};

export default GoogleLogin;
