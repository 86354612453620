import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import * as XLSX from 'xlsx';

const exportData = (dataToExport, columnsToExport) => {
  if (dataToExport.length === 0) return;
  const data = [...dataToExport];

  const columns = [...columnsToExport].filter((c) => {
    if (c.dataField === 'criadoEm') {
      return true;
    }
    if (c.isDummyField !== undefined && c.isDummyField === true) {
      return false;
    }
    if (c.hidden !== undefined && c.hidden === true) {
      return false;
    }
    if (c.dataField === 'id') {
      return false;
    }

    return true;
  });

  const csvData = [];
  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    const csvRow = {};
    for (const column of columns) {
      if (column.csvFormatter !== undefined) {
        csvRow[column.dataField] = column.csvFormatter(
          column.dataField,
          row,
          i,
          null
        );
      } else if (column.formatter !== undefined) {
        csvRow[column.dataField] = column.formatter(
          column.dataField,
          row,
          i,
          null
        );
      } else if (
        row[column.dataField] === null ||
        row[column.dataField] === undefined
      ) {
        csvRow[column.dataField] = '';
      } else {
        csvRow[column.dataField] = row[column.dataField];
      }
    }
    csvData.push(csvRow);
  }

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_aoa(ws, [[...columns.map((c) => c.text)]]);
  XLSX.utils.sheet_add_json(ws, csvData, { origin: 'A2', skipHeader: true });
  XLSX.utils.book_append_sheet(
    wb,
    ws,
    `relatorio-${moment().format('YYYYMMDDHHMMSS')}`
  );
  XLSX.writeFile(wb, `relatorio-${moment().format('YYYYMMDDHHMMSS')}.xlsx`);
};

const CsvComponent = (props) => {
  const handleClick = (e) => {
    e.preventDefault();

    exportData(props.data, props.columns);
  };
  return (
    <div>
      <button className="btn-primario-slim ml-2" onClick={handleClick}>
        <i className="fa fa-file-download px-2"></i>
      </button>
    </div>
  );
};

const SearchComponent = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.onSearch(input.value);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="input-theme busca mr-1"
        ref={(n) => (input = n)}
        type="text"
        onKeyUp={handleEnterKey}
      />
      <button
        className="btn-primario-slim"
        onClick={handleClick}
        style={{ width: 40, height: 40 }}
      >
        <i className="fa fa-search px-2"></i>
      </button>
    </div>
  );
};

export function getVisibilityByPermission(userPermission, allowedPermissions) {
  return !allowedPermissions.includes(userPermission);
}

const Datatable = forwardRef((props, ref) => {
  const [data, setData] = useState(props.isServerSide ? [] : props.data);
  const [dataCount, setDataCount] = useState(
    props.isServerSide ? 0 : props.data.length
  );
  const [loading, setLoading] = useState(props.loading);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortColumn, setSortColumn] = useState(props.defaultSortColumn);
  const [sortDirectionColumn, setSortDirectionColumn] = useState(
    props.defaultSortDirectionColumn
  );

  useEffect(() => {
    if (props.isServerSide) {
      searchData();
    }
  }, [page, limit, sortColumn, sortDirectionColumn]);

  useEffect(() => {
    if (!props.isServerSide) {
      setData(props.data);
      setDataCount(props.data.length);
    }
  }, [props.data]);

  useImperativeHandle(ref, () => ({
    exportSheet() {
      exportData(data, props.columns);
    },
    updateData() {
      searchData();
    },
    getData() {
      return data;
    },
  }));

  const searchData = async () => {
    setLoading(true);

    try {
      const result = await props.apiFetchFunction(props.filters, {
        pagina: page,
        limite: limit,
        ordernar: sortColumn
          ? `${sortDirectionColumn === 'asc' ? '+' : '-'}${sortColumn}`
          : '',
      });
      const keyData = Object.keys(result.value)[0];
      setData(result.value[keyData]);
      setDataCount(Number(result.metadata.total));
    } catch (error) {
      // NotificationService.notify(
      //   new FailedMessage().createMessage(error.message)
      // );
    }
    setLoading(false);
  };

  const onTableChange = (type, newState) => {
    switch (type) {
      case 'sort':
        setSortColumn(newState.sortField);
        setSortDirectionColumn(newState.sortOrder);

        break;
      case 'pagination':
        limit !== newState.sizePerPage && setLimit(newState.sizePerPage);
        page !== newState.page && setPage(newState.page);
        break;
      default:
        break;
    }
  };

  const rowOptions = props.rowIsSelectable
    ? {
        mode: 'checkbox',
        onSelect: props.onSelectRow,
        clickToSelect: false,
        hideSelectAll: !props.enableSelectAllRows,
        onSelectAll: props.onSelectAllRows,
        selected: props.selected,
      }
    : undefined;

  const paginationOptions = {
    page: props.isServerSide ? page : undefined,
    sizePerPage: props.isServerSide ? limit : undefined,
    totalSize: props.isServerSide ? dataCount : undefined,
    showTotal: true,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: dataCount,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span>
          {Number(from)}-{Number(to)} de {Number(size)} resultado(s)
        </span>
      ) : (
        <span>Nenhum resultado</span>
      ),
  };

  return (
    <ToolkitProvider
      bootstrap4
      keyField="id"
      search
      columns={props.columns}
      data={data}
    >
      {(toolkitProps) => (
        <>
          <div>
            <div className="d-flex flex-row-reverse mb-2">
              {props.enableInternalExport && (
                <CsvComponent
                  {...toolkitProps.csvProps}
                  data={data}
                  columns={props.columns}
                />
              )}
              <div className="mr-2">
                {props.enableInternalSearch && (
                  <SearchComponent {...toolkitProps.searchProps} />
                )}
              </div>
            </div>
          </div>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ _, paginationTableProps }) => (
              <div>
                {loading && <div id="table-loading" />}
                <BootstrapTable
                  onTableChange={props.isServerSide ? onTableChange : undefined}
                  remote={props.isServerSide}
                  filter={filterFactory()}
                  noDataIndication={() => <span>Sem resultados</span>}
                  selectRow={rowOptions}
                  classes="table-dark text-center rounded"
                  wrapperClasses="rounded"
                  bordered={false}
                  {...paginationTableProps}
                  {...toolkitProps.baseProps}
                ></BootstrapTable>
              </div>
            )}
          </PaginationProvider>
        </>
      )}
    </ToolkitProvider>
  );
});

Datatable.defaultProps = {
  enableInternalSearch: false,
  enableInternalExport: false,
  isServerSide: false,
};
export default Datatable;
