import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { isEmpty, map, toInteger } from 'lodash';

import './styles.css';
import { Row, Col, Container, Table } from 'react-bootstrap';
import api from '../../../services/API';
import capitalize from '../../../utils/capitalize';
import { useDispatch } from 'react-redux';
import { desautenticarUsuarioRelatorio } from '../../../store/modules/autenticacao/actions';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';
import Datatable from '../../DataTable';

export default function Dashboard() {
  const [parceirosCadastrados, setParceirosCadastrados] = useState(0);
  const [valorTotalNf, setValorTotalNf] = useState(0);
  const [parceirosPontuados, setParceirosPontuados] = useState(0);
  const [valorMedioNf, setValorMedioNf] = useState(0);
  const [usuariosTotais, setTopParceirosPontuados] = useState([]);
  const [topProdutos, setTopProdutos] = useState([]);
  const [novosParceiros, setNovosParceiros] = useState([]);
  const [notasEnviadas, setNotasEnviadas] = useState([]);
  const [totalVendas, setTotalVendas] = useState([]);

  const [buscando, setBuscando] = useState(false);
  const dispatch = useDispatch();

  const buscarDados = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/relatorio/dashboard`);
    // console.log(`resultado`, resultado);

    if (resultado) {
      setParceirosCadastrados(resultado.data.parceirosCadastrados);
      setParceirosPontuados(resultado.data.parceirosPontuados);
      setValorMedioNf(resultado.data.valorMedioNf);
      setTopParceirosPontuados(
        resultado.data.usuariosTotais.map((item) => ({
          ...item,
          totalVendido: item.totalVendido ? item.totalVendido : 0,
          mediaNf: item.mediaNf ? parseFloat(item.mediaNf).toFixed(2) : 0,
          pontuou: item.totalVendido ? 'Sim' : 'Não',
        }))
      );
      // console.log(
      //   `resultado.data.topProdutos`,
      //   resultado.data,
      //   resultado.data.topProdutos
      // );
      if (resultado.data.topProdutos) {
        setTopProdutos(resultado.data.topProdutos);
      }
      setValorTotalNf(resultado.data.valorTotalNf);
      setNovosParceiros(resultado.data.novosParceiros);
      setNotasEnviadas(resultado.data.notasEnviadas);
      setTotalVendas(resultado.data.totalVendas);
    }
    setBuscando(false);
  };

  useEffect(() => {
    buscarDados();
  }, []);

  const sair = () => {
    dispatch(desautenticarUsuarioRelatorio());
  };

  const columns = [
    {
      dataField: 'nome',
      text: 'Pintor',
      formatter: (celula, valor) => {
        return capitalize(valor.nome);
      },
      sort: true,
    },
    {
      dataField: 'celular',
      text: 'Celular',
      formatter: (celula, valor) => {
        return valor.celular.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      },
      sort: true,
    },
    {
      dataField: 'cidade',
      text: 'Cidade',
      sort: true,
    },
    {
      dataField: 'uf',
      text: 'UF',
      sort: true,
    },
    {
      dataField: 'qtdeNotas',
      text: 'Qtde Notas',
      sort: true,
    },
    {
      dataField: 'totalVendido',
      text: 'R$ Vendido',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.totalVendido).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
    },
    {
      dataField: 'mediaNf',
      text: 'Soma Média de NF',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.mediaNf)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      // formatter: (celula, valor) => {
      //   return valor.cpf.substring(0, 3) + '.XXX.XXX-' + valor.cpf.slice(-2);
      // },
      sort: true,
    },
    {
      dataField: 'pontuou',
      text: 'Pontuou?',
      sort: true,
      // formatter: (celula, valor) => {
      //   if (Number(valor.totalVendido) === 0) return 'Não';
      //   return 'Sim';
      // },
    },
  ];

  const columnsTotalEstado = [
    {
      dataField: 'produto',
      text: 'Produto',
      sort: true,
    },
    {
      dataField: 'qtde',
      text: 'Quantidade',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.qtde);
      },
    },

    {
      dataField: 'totalVendido',
      text: 'R$ Vendido',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.totalVendido)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
    },
  ];

  const ToSearch = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };

    const handleEnterKey = (e) => {
      if (e.key === 'Enter') {
        props.onSearch(input.value);
      }
    };

    return (
      <div className="d-flex justify-content-center align-items-center">
        <input
          className="input-theme busca mr-1"
          ref={(n) => (input = n)}
          type="text"
          onKeyUp={handleEnterKey}
        />
        <button
          className="btn-primario-slim"
          onClick={handleClick}
          style={{ width: 40, height: 40 }}
        >
          <i className="fa fa-search px-2"></i>
        </button>
      </div>
    );
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: usuariosTotais.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const ExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn-primario-slim ml-2" onClick={handleClick}>
          <i className="fa fa-file-download px-2"></i>
        </button>
      </div>
    );
  };

  const formatChart = (data, title) => {
    if (isEmpty(data)) return [];
    const array = ['Meses', title];
    const chartData = map(data, (item) => [
      item.mes,
      toInteger(item.total) ?? 0,
    ]);
    return [array, ...chartData];
  };

  const data = formatChart(novosParceiros, 'Novos Pintores');
  const dataNotas = formatChart(notasEnviadas, 'Notas');
  const dataTotal = formatChart(totalVendas, 'Total Notas');

  const optionsChart = {
    chart: {
      title: 'Novos pintores Cadastrados',
      subtitle: 'Pintores cadastrados nos ultimos 12 meses',
    },
  };

  const optionsNota = {
    chart: {
      title: 'Quantidade Notas Enviandas',
      subtitle: 'Quantidade de notas enviandas ultimos 12 meses',
    },
  };

  const optionsTotal = {
    chart: {
      title: 'Total Notas Enviandas',
      subtitle: 'Valor total de notas enviandas ultimos 12 meses',
    },
  };

  return (
    <Container fluid>
      <div className="d-flex flex-row justify-content-between">
        <h4 className="pt-3 pb-3 f-black">Relatório</h4>
        <button onClick={sair} className="border-0 shadow-none">
          Sair
        </button>
      </div>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="pb-2">
        <Col className="pb-1" xs={12} sm={6} lg>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores Cadastrados</strong>
              </span>
              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(parceirosCadastrados).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="pb-1" xs={12} sm={6} lg>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores Pontuados </strong>
              </span>

              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(parceirosPontuados).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="pb-1" xs={12} sm={6} lg>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores sem Pontuar</strong>
              </span>
              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(
                    parceirosCadastrados - parceirosPontuados
                  ).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="pb-1" xs={12} sm={6} lg>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>R$ Medio de NF</strong>
              </span>
              <div>
                <span
                  className="card-dash-subtitulo"
                  style={{ fontSize: '22px' }}
                >
                  {buscando ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    Number(valorMedioNf)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                      .toString()
                      .replace(/\u00a0/g, ' ')
                  )}
                </span>
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="pb-1" xs={12} sm={6} lg>
          <div className="card-dash">
            <span className="card-dash-titulo" style={{ width: '100%' }}>
              <strong>R$ Total de NF </strong>
            </span>
            <div>
              <span
                className="card-dash-subtitulo"
                style={{ fontSize: '22px' }}
              >
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(valorTotalNf)
                    .toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                    .toString()
                    .replace(/\u00a0/g, ' ')
                )}
              </span>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
      </Row>
      <Row className="p-3">
        <Col xs className="p-2" style={{ background: '#fff' }}>
          <Chart
            chartType="Bar"
            width="100%"
            height="200px"
            data={data}
            options={optionsChart}
          />
        </Col>
      </Row>
      <Row className="p-3">
        <Col xs className="p-2" style={{ background: '#fff' }}>
          <Chart
            chartType="Bar"
            width="100%"
            height="200px"
            data={dataNotas}
            options={optionsNota}
          />
        </Col>
      </Row>
      <Row className="p-3">
        <Col xs className="p-2" style={{ background: '#fff' }}>
          <Chart
            chartType="Bar"
            width="100%"
            height="300px"
            data={dataTotal}
            options={optionsTotal}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <div>
            <h5>Usuários X Valor</h5>
          </div>
          <Datatable
            data={usuariosTotais}
            columns={columns}
            enableInternalExport
            enableInternalSearch
            csvProps={{
              fileName: `Relatorio-${moment().format('DD/MM/YYYY')}.csv`,
              separator: ';',
              noAutoBOM: false,
            }}
          ></Datatable>
        </Col>
        <Col xs={12} xl={12} className="mb-4">
          <ToolkitProvider
            bootstrap4
            keyField="id"
            data={topProdutos}
            columns={columnsTotalEstado}
            exportCSV={{
              fileName: `Total-${moment().format('DD/MM/YYYY')}.csv`,
              separator: ';',
              noAutoBOM: false,
            }}
            search
          >
            {(props) => (
              <div>
                <div className="d-flex mb-2  justify-content-between ">
                  <h5>Top 20 produtos mais vendidos</h5>
                  <ExportCSV {...props.csvProps} />
                </div>
                <BootstrapTable
                  noDataIndication={() =>
                    buscando ? (
                      <span>
                        Carregando
                        <i className="fas fa-circle-notch fa-spin ml-3"></i>
                      </span>
                    ) : (
                      <span>Sem resultados</span>
                    )
                  }
                  classes="table-dark text-center rounded"
                  wrapperClasses="rounded"
                  bordered={false}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </Container>
  );
}
